// Navbar
.navbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;

  .navbar-section {
    align-items: center;
    display: flex;
    flex: 1 0 0;

    &:not(:first-child):last-child {
      justify-content: flex-end;
    }

    &.logo {
      // on mobile the logo is centered horz
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .navbar-center {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
  }

  .navbar-brand {
    font-size: $font-size-lg;
    font-weight: 500;
    text-decoration: none;
    margin-top: 10px;
  }
}


@media (min-width: $size-md) {
  .navbar {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .navbar-section {
      &.logo {
        // undo mobile centering
        position: relative;
        left: auto;
        transform: none;
      }
    }
  }
}
